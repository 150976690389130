import React from "react"
import {Overlay, FullscreenVideo, Layout} from "../components"
import {Story} from "../types"
import {PageProps} from "gatsby"
import {RecoilRoot} from "recoil"

const StoryPage = (page: PageProps): JSX.Element => {
  const story = page.pageContext as Story

  return (
    <RecoilRoot>
      <Layout title={story.title}>
        <FullscreenVideo video={story.video} story={story} />
        <Overlay currentStory={story} />
      </Layout>
    </RecoilRoot>
  )
}

export default StoryPage
